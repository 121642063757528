<template>
    <MyProfile @userDataLoaded="handleUserDataLoaded" />
    <button id="btn-nav" class="btn btn-primary btn-square position-fixed top-0 end-0 m-3" style="z-index: 999;" @click="toggleSidebar">☰</button>
    <!-- <button id="btn-nav" class="btn btn-unstyled btn-toggle" @click="toggleSidebar"></button> -->

    <div :class="sidebarClass" id="sidebar">
        <a href="/" class="d-flex align-items-center mb-md-0 me-md-auto text-white text-decoration-none">
            <!-- <svg class="bi pe-none me-2" width="40" height="32"><use xlink:href="#table" /></svg> -->
            <img class="bi pe-none me-2" height="40" :src="$site_logo" :alt="$site_name " />
            <span class="fs-4">{{ $site_name }}</span>
        </a>
        <hr>
        <ul v-if="user" class="nav nav-pills flex-column mb-auto">
            <template v-for="item in sidebarItems" :key="item.id">
                <li class="nav-item" v-if="isShouldRenderItem(item)">
                    <router-link :to="item.path" class="nav-link" :class="{ 'text-white': true, 'active': isActive(item) }"
                        :aria-current="item.path === $route.path ? 'page' : null" @click="handleMenuItemClick">

                        <svg class="bi pe-none me-2" width="16" height="16">
                            <use :xlink:href="'/svg/combined.svg#' + item.icon"></use>
                        </svg>
                        {{ item.title }}

                    </router-link>
                </li>
            </template>
            <hr v-if="!$route.path.startsWith('/home')">
            <li class="nav-item" v-if="!$route.path.startsWith('/home')">
                <router-link to="/home" class="nav-link" :class="{ 'text-white': true, 'active': false }" @click="handleMenuItemClick">
                    <svg class="bi pe-none me-2" width="16" height="16">
                        <use :xlink:href="'/svg/combined.svg#' + 'arrow-return'"></use>
                    </svg>
                    Back
                </router-link>
            </li>
        </ul>
        <hr>

        <div v-if="user" class="dropdown">
            <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img :src="user.url_avatar" alt="" width="32" height="32" class="rounded-circle me-2">
                <strong>{{ user.name }} {{ user.surname }}</strong>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow">
                <!-- enter to admin panel here -->
                <!-- <li><router-link class="dropdown-item" :to="path_new_collection">New collection...</router-link></li> -->
                <li><router-link class="dropdown-item" :to="path_profile" @click="handleMenuItemClick">Profile</router-link></li>
                <li><router-link class="dropdown-item" to="/home/access" @click="handleMenuItemClick">My Access</router-link></li>
                <li><router-link class="dropdown-item" :to="path_info" @click="handleMenuItemClick">Instructions</router-link></li>
                <li v-if="isBigScreen"><a class="dropdown-item" @click="closeSidebar">Close Sidebar</a></li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#" @click="signOut">Sign out</a></li>
            </ul>
        </div>
    </div>
</template>


<script>
import MyProfile from '@/components/module-common-my_profile.vue'
import { accountsService } from "@/components/ajax-common-account.js";
import router from "@/router";

export default {
    name: "CommonNavbar",
    components: {
        MyProfile
    },
    data() {
        return {
            user: null,
            error: '',
            loading: false,
            selected_item: '',

            path_profile: "/home/profile",
            path_new_collection: "/home/collections",
            path_info: "/home/info",
            isSidebarOpen: false,
            isBigScreen: true,
        }
    },
    props: {
        sidebarItems: {
            type: Array,
            default: function () {
                return [];
            },
            required: false,
        },
        selected_item_prop: {
            type: Number,
            default: 1,
            required: false,
        },
    },
    watch: {
        // selected_item: {
        //     // handler(newVal, oldVal) {
        //     handler(newVal) {
        //         this.error = '';
        //         if (this.getItem(newVal).title === 'Users') {
        //             if (this.users === '') { this.getAllUsers(); }
        //         } else if (this.getItem(newVal).title === 'Collections') {
        //             if (this.collections === '') { this.getAllCollections(); }
        //         } else if (this.getItem(newVal).title === 'Perms') {
        //             if (this.perms === '') { this.getAllPerms(); }
        //         }
        //     },
        //     immediate: true
        // }
    },
    methods: {
        closeSidebar() {
            this.toggleSidebar();
            alert("To open sidebar again refresh the page.");
        },
        handleResize() {
            // Проверяем размер экрана и устанавливаем значение isBigScreen
            this.isBigScreen = window.innerWidth >= 992;

            if (this.isBigScreen) this.isSidebarOpen = true;

            if (this.isBigScreen) {
                this.hideElementById("btn-nav");
            } else {
                this.showElementById("btn-nav");
            }

            this.handleMainBlock();
        },
        handleMainBlock() {
            if (this.isSidebarOpen && !this.isBigScreen) {
                this.hideElementById("main-block");
            } else {
                this.showElementById("main-block");
            }
        },
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;

            // urpylka temporary disabled
            // if (!this.isSidebarOpen) {
            //     this.showElementById("btn-nav");
            // }
            // else if (this.isBigScreen) {
            //     this.hideElementById("btn-nav");
            // }

            this.handleMainBlock();
        },
        handleMenuItemClick(itemid) {
            this.selected_item = itemid;
            if (!this.isBigScreen) this.isSidebarOpen = false;
            this.handleMainBlock();
        },
        handleUserDataLoaded(userProxy) {
            this.user = userProxy;
        },
        signOut() {
            accountsService.logout()
                // .then(res => {
                .then(() => {
                    router.push("/");
                })
        },
        getItem(itemid) {
            const itemToShow = this.sidebarItems.find(item => item.id === itemid);
            return itemToShow;
        },
        isShouldRenderItem(item) {
            // https://sky.pro/wiki/javascript/proverka-nalichiya-elementov-odnogo-massiva-v-drugom-v-js/
            return item.needs.length === 0 || item.needs.some(perm => this.user.perms.includes(perm));
        },
        isActive(item) {
            return this.$route.path === item.path;
        },
        hideElementById(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                element.classList.add('d-none');
            }
        },
        showElementById(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                element.classList.remove('d-none');
            }
        },
    },
    computed: {
        sidebarClass() {
            return {
                'd-flex': true,
                'flex-column': true,
                'flex-shrink-0': true,
                'p-3': true,
                'text-bg-dark': true,
                'col-lg-auto': this.isBigScreen,
                // 'col-lg-3': this.isBigScreen,
                'w-100': !this.isBigScreen,
                'd-none': !this.isSidebarOpen
            };
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.selected_item = this.selected_item_prop;
    },
    created() {
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>
<style>
/* settings */
@media (max-width: 992px) {
  #sidebar {
    display: none;
  }
}


.bi {
    vertical-align: -.125em;
    fill: currentColor;
}

/* .nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}

.nav-scroller .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
} */

/* sidebars */
.dropdown-toggle {
    outline: 0;
}

/* Убираем скругление углов */

.btn-unstyled {
  border-radius: 0;
}

.btn-toggle {
    padding: .25rem .5rem;
    font-weight: 600;
    color: var(--bs-emphasis-color);
    background-color: transparent;
}

.btn-toggle:hover,
.btn-toggle:focus {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
    background-color: var(--bs-tertiary-bg);
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

[data-bs-theme="dark"] .btn-toggle::before {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
    background-color: var(--bs-tertiary-bg);
}
</style>