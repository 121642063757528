<template>
    <h1>Personal Access Settings</h1>

    <ErrorBlockComponent ref="errorComponent" />

    Access below has been provided to you. You should accept it to use that functionality.

    <PersonalRolesComponent :setError="setError" />
    <PersonalGroupsComponent :setError="setError" />
</template>

<script>
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import PersonalRolesComponent from '@/components/module-smartmcw-show_user_roles.vue'
import PersonalGroupsComponent from '@/components/module-smartmcw-show_user_groups.vue'

export default {
  name: "PersonalAccessSettings",

  components: {
    ErrorBlockComponent,
    PersonalRolesComponent,
    PersonalGroupsComponent,
  },

  data() {
    return {
    }
  },

  methods: {
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>
