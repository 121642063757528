<template>
  <main class="d-flex flex-nowrap workspace-container">
    <Navbar :sidebarItems="sidebar_items" :selected_item_prop="6" />
    <div class="col-lg w-100" id="main-block">
      <div class="main-content scrollarea">
        <router-view></router-view>
        <!-- <router-view :navbarVariable="navbarVariable"></router-view> -->
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from '@/components/module-common-navbar.vue'

export default {
  name: "SmartmcwWorkspace",
  components: {
    Navbar
  },
  data() {
    return {
      sidebar_items: [
        { id: 6, title: 'Organizations', icon: 'company', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw/orgs' },
        { id: 7, title: 'Cafes', icon: 'cafe', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw/cafes' },
        { id: 8, title: 'Access', icon: 'secure', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw/access' },
        { id: 9, title: 'Menus', icon: 'fork', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw/menus' },
        { id: 10, title: 'Mcws', icon: 'mcw', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw/mcws' },
      ]
    }
  }
}
</script>
<style>
html {
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.workspace-container {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

/* main_content */
.main-content {
    padding: 20px;
    background-color: #ffffff;
    height: 100%;
}

.mr-2 {
    margin-right: 10px;
}

.table-active {
    --bs-table-active-bg: rgba(0, 0, 0, 0.37);
}

.scrollarea {
    overflow-y: auto;
}
</style>