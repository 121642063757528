<template>
  <main class="d-flex flex-nowrap workspace-container">
    <Navbar :sidebarItems="sidebar_items" :selected_item_prop="1" />
    <div class="col-lg w-100" id="main-block">
      <div class="main-content scrollarea">
        <router-view></router-view>
        <!-- <router-view :navbarVariable="navbarVariable"></router-view> -->
      </div>
    </div>
  </main>
</template>

<script>
import Navbar from '@/components/module-common-navbar.vue'

export default {
  name: "CustomerWorkspace",
  components: {
    Navbar
  },
  data() {
    return {
      sidebar_items: [
        { id: 1, title: 'Home', icon: 'home', needs: [], path: '/home/profile' },
        { id: 11, title: 'Barista', icon: 'cook', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/home/barista' },
        { id: 12, title: 'Admin: MCW', icon: 'parameter', needs: ['smartmcw_user', 'smartmcw_admin'], path: '/smartmcw' },
        { id: 13, title: 'Admin: CK', icon: 'parameter', needs: ['ck_user', 'ck_admin'], path: '/ck' },
        { id: 14, title: 'Admin: Site', icon: 'parameter', needs: ['manage_accounts', 'manage_perms'], path: '/admin' },
      ]
    }
  }
}
</script>
<style>
html {
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.workspace-container {
    height: 100vh;
    height: -webkit-fill-available;
    max-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
}

/* main_content */
.main-content {
    padding: 20px;
    background-color: #ffffff;
    height: 100%;
}

.mr-2 {
    margin-right: 10px;
}

.table-active {
    --bs-table-active-bg: rgba(0, 0, 0, 0.37);
}

.scrollarea {
    overflow-y: auto;
}
</style>