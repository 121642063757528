<template>
  <MyProfile @userDataLoaded="handleUserDataLoaded" />
  <div v-if="user">
    <ErrorBlockComponent ref="errorComponent" />

    <div class="row align-items-start gap-1">

      <div class="col-auto">
        <img class="rounded" :src="user.url_avatar" alt="avatar">
      </div>

      <div class="col">

        <div class="d-flex gap-1">
          <h2>{{ user.name }} {{ user.surname }}</h2>
          <div v-if="user.is_blocked">
            <span class="badge text-bg-danger top-0 start-0">blocked</span>
          </div>
        </div>

        <p>
          <b>Email:</b> {{ user.email }} <span style="font-size: xx-small;"
            class="position-realative badge top-0 start-0"
            :class="user.email_is_verified ? 'text-bg-success' : 'text-bg-danger'">
            {{ user.email_is_verified ? 'verified' : 'unverified' }}</span>
        </p>

        <p v-if="$is_debug" class="m-0"><b>Created at:</b> {{ user.created_at }}</p>
        <p v-if="$is_debug" class="m-0"><b>Update on:</b> {{ user.update_on }}</p>

        <div class="d-flex flex-wrap mt-2 gap-2">
          <div style="white-space: nowrap;">
            <button type="button" style="font-size: small;" class="btn btn-link p-0" disabled>Change Name</button>
          </div>
          <div style="white-space: nowrap;">
            <button type="button" style="font-size: small;" class="btn btn-link p-0" disabled>Change Email</button>
          </div>
          <div style="white-space: nowrap;">
            <button type="button" style="font-size: small;" class="btn btn-link p-0" disabled>Change Password</button>
          </div>
        </div>

      </div>
    </div>
    <hr />

    <div v-if="$is_debug">
      <h2 class="mt-3">Permissions</h2>
      <ul class="flex-column mb-auto">
        <li v-for="perm in user.perms" :key="perm.id">
          {{ perm }}
        </li>
      </ul>
      <hr />
    </div>

    <SessionComponent :setError="setError" />
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorBlockComponent from '@/components/module-common-errorblock.vue'
import MyProfile from '@/components/module-common-my_profile.vue'
import SessionComponent from '@/components/module-common-showsession.vue'

export default {
  name: "MyHome",

  components: {
    ErrorBlockComponent,
    MyProfile,
    SessionComponent,
  },

  data() {
    return {
      user: null,
    }
  },

  methods: {
    handleUserDataLoaded(userProxy) {
      this.user = userProxy;
    },
    async setError(text) {
      this.$refs.errorComponent.setError(text);
    },
  },
}
</script>
<style scoped>
/* @media (max-width: 768px) {
  .flex-wrap > div {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .flex-wrap > div {
    display: inline-block !important;
  }
} */
</style>
